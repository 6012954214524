#hero {
	display: flex;

	.s-inner-wrap {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.logo {
		max-width: 550px;
		margin-left: 2rem;
		margin-right: 2rem;
		display: inline-block;
		width: 100%;
		margin-bottom: 2rem;
	}

	h1 {
		font-size: var(--fluid-h5);
		color: white;
		font-weight: 600;
		text-align: center;

		@include mq(m) {
			font-size: var(--fluid-h4);
		}
	}

	.see-show {
		text-decoration: none;
		text-transform: uppercase;
		display: flex;
		flex-flow: column nowrap;
		gap: 15px;
		font-size: var(--fluid-h6);
		margin-top: var(--fluid-h3);

		img {
			height: var(--fluid-h4);
		}
	}
}

#show {
	.s-inner-wrap {
		overflow: visible;
	}
	h2 {
		font-family: $body-font-name;
		color: $primary-color;
		text-transform: uppercase;
		font-size: var(--fluid-h5);
		text-align: center;

		@include mq(m) {
			font-size: var(--fluid-h4);
		}

		span {
			color: $white;
		}
	}

	.show-wrap {
		padding-left: 0;
		padding-right: 0;
		display: flex;
		flex-flow: column nowrap;
		width: 100%;

		@include mq(m) {
			padding-left: 2rem;
			padding-right: 2rem;
		}

		.show-iframe {
			position: relative;
			padding-bottom: 56.25%;
			width: 100%;
			min-width: 100%;
			flex-shrink: 0;
			height: auto;
			align-self: stretch;

			iframe {
				position: absolute;
				height: 100%;
				width: 100%;
				overflow: hidden;
			}

			> img {
				position: absolute;
				height: 100%;
				width: 100%;
				overflow: hidden;
				object-fit: cover;
			}
		}

		.tree-left {
			position: absolute;
			bottom: -2.5rem;
			height: 70%;
			z-index: 1;
			width: 100%;
			max-width: 53px;
			left: -2.25rem;

			@include mq(m) {
				left: -8rem;
				max-width: 147px;
			}

			svg {
				height: 100%;
				width: 100%;
			}
		}

		.tree-right {
			position: absolute;
			bottom: -2.5rem;
			height: 70%;
			width: 100%;
			max-width: 57px;
			right: -2.25rem;

			@include mq(m) {
				right: -9rem;
				max-width: 172px;
			}

			svg {
				height: 100%;
				width: 100%;
			}
		}
	}

	h3 {
		padding-top: 40px;
		color: $white;
		max-width: 1000px;
		text-align: center;

		b {
			color: $primary-color;
		}

		a {
			color: $primary-color;
			text-decoration: none;
			font-weight: bold;
			@include transition;

			&:hover {
				color: darken($primary-color, 10%);
			}
		}
	}

	a.map {
		color: $primary-color;
		text-decoration: none;
		font-weight: bold;
		margin: 2rem 0 1rem;
		font-size: var(--fluid-h5);
		@include transition;

		&:hover {
			color: darken($primary-color, 10%);
		}
	}

	.btn-wrap {
		width: 100%;
		max-width: 600px;
		margin-top: 20px;

		@include mq(l) {
			max-width: 100%;
		}

		.f-grid {
			--cell-size-lg: 16.666%;
			--cell-size-md: 33.333%;
			--cell-size-sm: 33.333%;
			--grid-gap: 10px;

			@include mq(m) {
				--grid-gap: 20px;
			}

			@include mq(l) {
				--grid-gap: 25px;
			}
			width: 100%;
		}

		button {
			display: flex;
			flex-flow: column nowrap;
			background: transparent;
			border: unset;
			cursor: pointer;
			color: white;
			font-weight: 700;
			font-size: 13px;

			@include mq(m) {
				font-size: var(--fluid-body);
			}

			.icon {
				position: relative;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				justify-content: space-around;
				aspect-ratio: 1;
				width: 100%;
				font-family: liza-display-new, sans-serif;
				background-color: $primary-color;
				align-content: center;
				justify-content: center;
				border-radius: 100%;
				margin-bottom: 20px;

				svg {
					max-width: 45%;
					width: 100%;
					margin-right: 5px;
					padding-bottom: 10px;
					transition: all 0.5s ease;
				}

				.number {
					display: block;
					@include fluid-prop("font-size", 60px, 110px);
					color: white;
					padding-top: 15px;
					line-height: 1;
					transition: color 0.3s ease;
				}

				&:after {
					position: absolute;
					content: url("../images/swirl.svg");
					top: 0;
					left: 0;
					height: 100%;
					width: 120%;
					transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
				}
			}

			&:hover,
			&:active,
			&:focus {
				.number {
					color: $light-gray;
				}
				svg {
					transform: rotate(15deg);
				}
			}

			&:active {
				.icon:after {
					transform: rotate(20deg);
				}
			}
		}

		#buttons {
			.cell:nth-child(6n + 1) {
				.icon {
					background-color: $tertiary-color;
				}
			}

			.cell:nth-child(6n + 2) {
				.icon {
					background-color: $primary-color;
				}
			}

			.cell:nth-child(6n + 3) {
				.icon {
					background-color: $btn-dark-green;
				}
			}

			.cell:nth-child(6n + 4) {
				.icon {
					background-color: $tertiary-color-light;
				}
			}

			.cell:nth-child(6n + 5) {
				.icon {
					background-color: $btn-light-red;
				}
			}

			.cell:nth-child(6n) {
				.icon {
					background-color: $btn-light-green;
				}
			}
		}
	}

	.queue-header {
		background: url(../images/wait-in-line.svg) no-repeat center center;
		background-size: contain;
		padding: 1rem;
		margin-top: 40px;
		width: 100%;
		max-width: 600px;
		background-size: cover;
		h2 {
			font-size: var(--fluid-h6);
			text-align: center;
			font-weight: bold;
			color: $white;
			text-transform: uppercase;
			line-height: var(--fluid-h6);
			margin: 0;
			position: relative;
			top: -3px;
			@include mq(m) {
				font-size: var(--fluid-h5);
				line-height: var(--fluid-h5);
			}

			span {
				margin-top: 0.75rem;
				color: $white;
				position: relative;
				top: 3px;
			}
		}
		img {
			height: var(--fluid-h4);
			margin: 0 0.25rem;

			@include mq(m) {
				margin: 0 1.5rem;
			}
		}
	}
	h3#nowPlayingLeaderboard {
		font-family: $body-font-name;
		color: $primary-color;
		text-transform: uppercase;
		font-size: var(--fluid-h5);
		text-align: center;

		strong {
			color: $primary-color;
		}

		span {
			color: $white;
		}
	}

	.queue-wrap {
		background-color: $light-gray;
		border-radius: 25px;
		padding: 1rem;
		margin-top: 5rem;
		position: relative;
		width: 100%;
		max-width: 720px;
		height: 380px;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			border: 3px solid $border-green;
			width: 100%;
			height: 100%;
			top: -0.5rem;
			border-radius: 25px;
			left: 1rem;
			z-index: 2;
		}

		#leaderBoard {
			z-index: 10;
			position: relative;
			max-height: 330px;
			overflow: auto;
		}
		.trees-left {
			position: absolute;
			left: -8rem;
			bottom: 1rem;
			height: auto;
			z-index: 1;
			width: 100%;
			max-width: 162px;
			display: none;
			z-index: 5;

			@include mq(l) {
				display: block;
			}

			svg {
				height: 100%;
				width: auto;
			}
		}

		.trees-right {
			position: absolute;
			right: -7rem;
			bottom: 2rem;
			height: 100%;
			width: 100%;
			max-width: 149px;
			display: none;
			z-index: 5;

			@include mq(l) {
				display: block;
			}

			svg {
				height: 100%;
				width: auto;
			}
		}
		.lights-top {
			position: absolute;
			top: -4rem;
			width: 100%;
			max-width: 558px;
			left: 50%;
			transform: translateX(-50%);
			z-index: 5;

			svg {
				width: 100%;
			}
		}

		.lights-bottom {
			position: absolute;
			width: 100%;
			max-width: 558px;
			left: 50%;
			transform: translateX(-50%);
			bottom: -4rem;
			z-index: 5;

			svg {
				width: 100%;
			}
		}

		table {
			margin: 1rem auto 0;
			tr {
				display: block;
				padding: 8px;
				margin-bottom: 10px;
				background: white;
				border-radius: 200px;
				overflow: hidden;
				td:first-child {
					max-width: 75px;
					min-width: 52px;
				}

				td:nth-child(3n + 2) {
					color: $secondary-color;
					padding-left: 0.2rem;
					padding-right: 0.2rem;
					font-size: var(--fluid-body-xs) !important;

					@include mq(m) {
						font-size: var(--fluid-body);
					}
				}

				td:last-child {
					text-transform: uppercase;
					font-size: var(--fluid-body-xs) !important;

					@include mq(m) {
						font-size: var(--fluid-body);
					}

					span {
						color: $primary-color;
					}
				}
			}
		}
	}
}

#holiday-tunes {
	.s-inner-wrap {
		padding-top: 0;
	}
	.tunes-wrap {
		text-align: center;
		font-family: liza-display-new, sans-serif;
		background-color: $light-gray;
		padding: var(--fluid-h6);
		border-radius: 25px;
		max-width: 800px;
		width: 100%;
		margin: 0 auto;

		.fancy-header {
			font-family: liza-display-new, sans-serif;
			font-size: var(--fluid-h2);
			margin-bottom: 2rem;

			@include mq(m) {
				font-size: var(--fluid-h1);
			}
		}

		.spotify-wrap {
			border-radius: 20px;
			overflow: hidden;
		}
	}
}

.footer {
	.s-inner-wrap {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	background: $tertiary-color;

	.privacy,
	.socials {
		margin: 1rem 0 0;
		a {
			text-decoration: none;
			color: $white;
			@include transition;
			&:not(:last-child) {
				margin-right: 1rem;
			}

			svg path {
				@include transition;
			}
			&:hover {
				color: darken($white, 10%);
				svg path {
					fill: darken($white, 10%);
				}
			}
		}
	}

	img {
		max-width: 200px;
	}
	.copyright {
		color: white;
		font-family: $header-font-family;
		font-weight: 400;
		margin-bottom: 0;
		font-weight: bold;
	}
}

body {
	background: $secondary-color url(../images/hero-background.png) repeat center top;
	background-size: contain;
	background-attachment: fixed;
	overflow-x: hidden;
}

#infographic .s-inner-wrap {
	padding-top: 1rem;

	.fancy-header {
		font-family: liza-display-new, sans-serif;
		margin-bottom: 2rem;
		color: $white;
		@include fluid-prop(font-size, 32px, 72px);

		@include mq(m) {
			@include fluid-prop(font-size, 30px, 62px);
		}
	}
}

#infoModal {
	position: relative;
	background: $light-gray;
	border-radius: 25px;
	width: 92%;
	margin: 0 auto;
	max-width: 720px;
	&:after {
		content: "";
		position: absolute;
		left: 0;
		border: 3px solid $border-green;
		width: 100%;
		height: 100%;
		top: -0.5rem;
		border-radius: 25px;
		left: 1rem;
		z-index: 2;
	}

	#modalAvatar {
		top: -3rem;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		z-index: 5;
	}
	i.big-tree {
		position: absolute;
		left: -4rem;
		top: auto;
		bottom: 0;
		z-index: 5;

		@include mq(m) {
			top: -1rem;
		}
		svg {
			height: 144px;

			@include mq(m) {
				height: 387px;
			}
		}
	}

	#modalClose img {
		height: var(--fluid-h4);
		display: block;
		text-align: center;
		margin: 0 auto;
	}
	.content {
		width: 100%;

		.inner-text {
			padding: 6rem 2rem 2rem 4rem;

			@include mq(m) {
				padding: 4rem 6rem 2rem 9rem;
			}

			h3.text-center {
				font-family: $body-font-name;
				font-size: var(--fluid-h4);
				text-transform: uppercase;
				line-height: 1;
			}
			h4 {
				color: $secondary-color;
				font-size: var(--fluid-h4);
				line-height: 1;
				margin-bottom: 2rem;
			}
			div.text-center {
				font-weight: 500;
				line-height: 1.5;
			}
			a#modalClose {
				text-transform: uppercase;
				text-decoration: none;
				text-align: center;
				margin: 0 auto;
				display: block;
				position: relative;
				z-index: 10;
				img {
					margin-top: 0.5rem;
				}
			}
		}
	}
}
