$white: #ffffff;
$black: #313131;
$gray: #a2a2a2;
$light-gray: #dfeded;

$primary-color: #be1e2d;
$secondary-color: #209999;
$tertiary-color: #ef6f17;
$quaternary-color: orange;

$btn-dark-green: #0a7071;
$btn-light-red: #fc495d;
$btn-light-green: #5ac0c1;

$border-green: #48abac;
